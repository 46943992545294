import React, { useState } from 'react'
import styled from 'styled-components'
import { useFlexSearch } from 'react-use-flexsearch'
import useOnclickOutside from 'react-cool-onclickoutside'
import { color, font } from '../styles'
import iconSearch from './icon-search.svg'
import iconInactive from './icon-article-inactive.svg'
import iconClear from './icon-clear.svg'

const Wrapper = styled.div`
  position: relative;
  @media (min-width: 1280px) {
    display: block;
  }
  @media (max-width: 1279px) {
    display: none;
  }
`
const Input = styled.input`
  width: 20rem;
  height: 2.5rem;
  padding: 0 0.75rem 0 2.75rem;
  border: 0.0625rem solid ${color.inputBackground};
  border-radius: 8px;
  background: ${color.inputBackground};
  ${font.label}
  color: ${color.primary};
  outline: none;
  &:focus {
    border: 0.0625rem solid ${color.brandPrimary};
  }
`
const IconStart = styled.img`
  position: absolute;
  left: 0.75rem;
  top: 0.5rem;
`
const IconEnd = styled.img`
  position: absolute;
  right: 0.75rem;
  top: 0.5rem;
  cursor: pointer;
`
const SearchResults = styled.div`
  position: absolute;
  width: 20rem;
  top: 2.75rem;
  left: 0;
  padding: 0.25rem 0;
  background: ${color.washWhite};
  max-height: 35rem;
  overflow: scroll;
  box-shadow: 0 0.5rem 1.5rem -0.25rem rgba(0, 0, 0, 0.12),
              0 0.0625rem 0.0625rem rgba(0, 0, 0, 0.04),
              0 0 1rem -0.5rem rgba(0, 0, 0, 0.08);
  border-radius: 0.5rem;
`
const SearchItem = styled.a`
  display: flex;
  align-items: start;
  cursor: pointer;
  width: 100%;
  padding: 0.5rem 0.75rem;
  background: ${color.washWhite};
  text-decoration: none;
  color: ${color.primary};
  img {
    margin-right: 0.75rem;
  }
  div {
    display: flex;
    flex-direction: column;
  }
  h4 {
    ${font.h4}
    color: ${color.primary};
    margin-bottom: 0.125rem;
  }
  p {
    ${font.h4}
    font-weight: 400;
    color: ${color.secondary};
  }
  &:hover {
    background: ${color.washOffWhite};
  }
`

const Search = ({ index, store }) => {
  const [query, setQuery] = useState('')
  const results = useFlexSearch(query, index, store)
  const ref = useOnclickOutside(() => {
    setQuery('')
  })
  return (
    <Wrapper>
      <Input
        type='text'
        placeholder='Search articles'
        value={query}
        onChange={e => setQuery(e.target.value)}
        ref={ref}
      />
      <IconStart
        src={iconSearch}
        alt='search'
        ref={ref}
      />
      {query.length > 0 && (
        <IconEnd
          src={iconClear}
          alt='clear'
          onClick={() => setQuery('')}
        />
      )}
      {results.length > 0 && (
        <SearchResults ref={ref}>
          {results.map((result) => (
            <SearchItem key={result.id} href={`/${result.slug}`}>
              <img src={iconInactive} alt="article" />
              <div>
                <h4>{result.title}</h4>
                <p>{result.description}</p>
              </div>
            </SearchItem>
          ))}
        </SearchResults>
      )}
    </Wrapper>
  )
}

export default Search