import * as React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import { color, font } from '../styles'
import iconInactive from './icon-article-inactive.svg'
import iconActive from './icon-article-active.svg'

const PublishedItem = styled(Link)`
  background: ${color.washOffWhite};
  height: 3rem;
  padding: 0.75rem 1rem;
  display: flex;
  align-items: center;
  cursor: pointer;
  img {
    margin-right: 0.75rem;
    &:nth-child(1) {
      display: block;
    }
    &:nth-child(2) {
      display: none;
    }
  }
  p {
    ${font.meta}
    color: ${color.secondary};
  }
  &:hover {
    background: ${color.brandWash};
  }
  &.active {
    background: ${color.brandWash};
    p {
      font-weight: 700;
    }
    img:nth-child(1) {
      display: none; 
    }
    img:nth-child(2) {
      display: block;
    }
  }
`
const NonpublishedItem = styled.div`
  background: ${color.washOffWhite};
  height: 3rem;
  padding: 0.75rem 1rem;
  display: flex;
  align-items: center;
  cursor: not-allowed;
  img {
    margin-right: 0.75rem;
    opacity: 0.5;
  }
  p {
    ${font.meta}
    color: ${color.tertiary};
  }
`

const NavItem = ({ article, setIsMenuCollapsed }) => {
  if (article.frontmatter.published) {
    return (
      <PublishedItem
        to={`/${article.slug}`}
        activeClassName="active"
        onClick={() => setIsMenuCollapsed(true)}
      >
        <img src={iconInactive} alt="article" />
        <img src={iconActive} alt="article" />
        <p>{article.frontmatter.title}</p>
      </PublishedItem>
    )
  } else {
    return (
      <NonpublishedItem>
        <img src={iconInactive} alt="article" />
        <p>{article.frontmatter.title}</p>
      </NonpublishedItem>
    )
  }
}

export default NavItem