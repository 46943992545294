import React, { useState } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { useFlexSearch } from 'react-use-flexsearch'
import styled from 'styled-components'
import { font, color } from '../styles'
import NavCategory from './navCategory'
import NavSearchResult from './navSearchResult'
import iconSearch from './icon-search.svg'
import iconClear from './icon-clear.svg'

const Wrapper = styled.nav`
  position: fixed;
  top: 4rem;
  left: 0;
  bottom: 0;
  width: 17.5rem;
  min-width: 17.5rem;
  min-height: calc(100vh - 4rem);
  background: ${color.washOffWhite};
  border-right: 1px solid ${color.divider};
  z-index: 2;
  overflow: scroll;
  @media (max-width: 1279px) {
    width: 100%;
    display: ${props => props.isMenuCollapsed && 'none'};
  }
`
const MobileSearch = styled.div`
  background: ${color.washWhite};
  border-bottom: 0.0625rem solid ${color.divider};
  padding: 1rem;
  @media (min-width: 1280px) {
    display: none;
  }
  @media (max-width: 1279px) {
    display: block;
  }
`
const MobileSearchInput = styled.input`
  width: 100%;
  height: 2.5rem;
  padding: 0 0.75rem 0 2.75rem;
  border: 0.0625rem solid ${color.inputBackground};
  border-radius: 8px;
  background: ${color.inputBackground};
  ${font.label}
  color: ${color.primary};
  outline: none;
  &:focus {
    border: 0.0625rem solid ${color.brandPrimary};
  }
`
const IconStart = styled.img`
  position: absolute;
  left: 1.75rem;
  top: 1.5rem;
`
const IconEnd = styled.img`
  position: absolute;
  right: 1.75rem;
  top: 1.5rem;
  cursor: pointer;
`

const Navbar = ({ isMenuCollapsed, setIsMenuCollapsed, searchData }) => {
  const [query, setQuery] = useState('')
  const searchResults = useFlexSearch(query, searchData.localSearchArticles.index, searchData.localSearchArticles.store)

  // get full article list
  const articleData = useStaticQuery(graphql`
    query {
      allMdx(sort: {
        fields: [frontmatter___indexModule, frontmatter___indexArticle]
      }) {
        nodes {
          id
          slug
          frontmatter {
            module
            title
            published
          }
        }
      }
    }
  `)
  let articles = articleData.allMdx.nodes

  // get list of unique modules
  let moduleNames = new Set(articles.map(article => article.frontmatter.module))

  // for each unique module, add that module's articles to groupedArticles
  let groupedArticles = []
  moduleNames.forEach((module) => {
    groupedArticles.push(
      articles.filter(article => article.frontmatter.module === module)
    )
  })

  // for each group of articles, render a category
  let renderedItems = groupedArticles.map((articles, i) => {
    return <NavCategory key={i} articles={articles} setIsMenuCollapsed={setIsMenuCollapsed}/>
  })

  // if searching, render search results instead
  if (query.length > 0) {
    renderedItems = searchResults.map((article, i) => {
      return <NavSearchResult key={i} article={article} setIsMenuCollapsed={setIsMenuCollapsed} setQuery={setQuery}/>
    })
  }

  
  return (
    <Wrapper isMenuCollapsed={isMenuCollapsed}>
      <MobileSearch>
        <MobileSearchInput
          type='text'
          placeholder='Search articles'
          value={query}
          onChange={e => setQuery(e.target.value)}
        />
        <IconStart
          src={iconSearch}
          alt='search'
        />
        {query.length > 0 && (<IconEnd
          src={iconClear}
          alt='clear'
          onClick={() => setQuery('')}
        />)}
      </MobileSearch>
      {renderedItems}
    </Wrapper>
  )
}

export default Navbar