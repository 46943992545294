import * as React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import { color, font } from '../styles'
import iconInactive from './icon-article-inactive.svg'
import iconActive from './icon-article-active.svg'

const Item = styled(Link)`
  background: ${color.washOffWhite};
  padding: 0.75rem 1rem;
  display: flex;
  align-items: start;
  cursor: pointer;
  img {
    margin-right: 0.75rem;
    &:nth-child(1) {
      display: block;
    }
    &:nth-child(2) {
      display: none;
    }
  }
  div {
    display: flex;
    flex-direction: column;
  }
  h4 {
    ${font.h4}
    color: ${color.primary};
    margin-bottom: 0.125rem;
  }
  p {
    ${font.meta}
    color: ${color.secondary};
    text-overflow: ellipsis;
    text-decoration: none;
  }
  &:hover {
    background: ${color.brandWash};
  }
  &.active {
    background: ${color.brandWash};
    h4 {
      font-weight: 700;
    }
    img:nth-child(1) {
      display: none; 
    }
    img:nth-child(2) {
      display: block;
    }
  }
  &:first-child {
    margin-top: 0.5rem;
  }
  &:last-child {
    margin-bottom: 0.5rem;
  }
`

const NavSearchResult = ({ article, setIsMenuCollapsed, setQuery }) => {
  return (
    <Item
      to={`/${article.slug}`}
      activeClassName="active"
      onClick={() => {
        setIsMenuCollapsed(true)
        setQuery('')
      }}
    >
      <img src={iconInactive} alt="article" />
      <img src={iconActive} alt="article" />
      <div>
        <h4>{article.title}</h4>
        <p>{article.description}</p>
      </div>
    </Item>
  )
}

export default NavSearchResult