import React, { useState } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import styled, { createGlobalStyle } from 'styled-components'
import Header from './header'
import Navbar from './navbar'
import { color, font, transition } from '../styles'

const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
  }
  html, body, div, span, applet, object, iframe,
  h1, h2, h3, h4, h5, h6, p, blockquote, pre,
  a, abbr, acronym, address, big, cite, code,
  del, dfn, em, img, ins, kbd, q, s, samp,
  small, strike, strong, sub, sup, tt, var,
  b, u, i, center,
  dl, dt, dd, ol, ul, li,
  fieldset, form, label, legend,
  table, caption, tbody, tfoot, thead, tr, th, td,
  article, aside, canvas, details, embed,
  figure, figcaption, footer, header, hgroup,
  main, menu, nav, output, ruby, section, summary,
  time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
  }
  /* HTML5 display-role reset for older browsers */
  article, aside, details, figcaption, figure,
  footer, header, hgroup, main, menu, nav, section {
    display: block;
  }
  /* HTML5 hidden-attribute fix for newer browsers */
  *[hidden] {
      display: none;
  }
  html, body {
    height: 100%;
    min-height: 100%;
  }
  body {
    line-height: 1;
  }
  #___gatsby, #gatsby-focus-wrapper {
    height: 100%;
  }
  ol, ul {
    list-style: none;
  }
  blockquote, q {
    quotes: none;
  }
  blockquote:before, blockquote:after,
  q:before, q:after {
    content: '';
    content: none;
  }
  table {
    border-collapse: collapse;
    border-spacing: 0;
  }
  a {
    text-decoration: none;
  }
  h1 {
    ${font.h1}
  }
  @media (max-width: 1279px) {
    h1 {
      font-size: 2.25rem;
      line-height: 2.25rem;
    }
  }
  h2 {
    ${font.h2}
  }
  h3 {
    ${font.h3}
  }
  h4, h5, h6 {
    ${font.h4}
  }
  p, li {
    ${font.p}
  }
  article {
    color: ${color.primary};
  }
  article h1 {
    margin-bottom: 1.5rem;
  }
  article h2 {
    margin: 3rem 0 1rem 0;
  }
  article h3 {
    margin: 2rem 0 0.5rem 0;
  }
  article p, article ul, article ol {
    margin-bottom: 1rem;
  }
  article li {
    margin-bottom: 0.25rem;
  }
  @media (max-width: 1279px) {
    article li {
      margin-left: 1.125rem;
    }
  }
  article ul {
    list-style-type: square;
  }
  article ol {
    list-style-type: lower-roman;
  }
  article p a, article li a {
    text-decoration: none;
    background: ${color.brandLight};
    color: ${color.primary};
    transition: background 0.08s ${transition.easeOut};
    z-index: 1;
    padding: 0.0625rem 0.25rem;
    border-radius: 0.125rem;
    &:hover {
      background: ${color.brandPrimary};
    }
  }
  article strong {
    font-weight: 700;
  }
  article blockquote {
    border-left: 0.1875rem solid ${color.brandPrimary};
  }
  article blockquote p {
    color: ${color.secondary};
    font-style: italic;
  }
  @media (min-width: 1280px) {
    article blockquote {
      margin: 0 0 2rem -1.5rem;
      padding: 0 0 0 1.5rem;
    }
  }
  @media (max-width: 1279px) {
    article blockquote {
      margin: 0 0 2rem 0;
      padding: 0 0 0 1rem;
    }
  }
  article hr {
    border-bottom: none;
    border-top: 0.0625rem solid ${color.divider};
    margin-bottom: 2rem;
  }
  article table {
    width: 100%;
    border-collapse: collapse;
  }
  article table tr th, article table tr td {
    height: 2.5rem;
    border: 0.0625rem solid ${color.inputBackground};
    vertical-align: middle;
    padding: 0.75rem;
  }
  article table thead {
    background: ${color.inputBackground};
    font-weight: 700;
  }
  article table tbody tr:nth-child(even) {
    background: ${color.washOffWhite};
  }
`
const Main = styled.main`
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: row;
`
const Body = styled.div`
  width: 100%;
  display: flex;
  min-height: 100%;
  margin-top: 4rem;
  @media (min-width: 1280px) {
    margin-left: 17.5rem;
    padding: 4rem;
  }
  @media (max-width: 1279px) {
    padding: 1.5rem 1rem 2rem 1rem;
  }
`

const Layout = ({ children }) => {
  const [isMenuCollapsed, setIsMenuCollapsed] = useState(true)

  const searchData = useStaticQuery(graphql`
    query SearchIndexQuery {
      localSearchArticles {
        index
        store
      }
    }
  `)

  return (
    <>
      <GlobalStyle />
      <Main>
        <Header
          isMenuCollapsed={isMenuCollapsed}
          setIsMenuCollapsed={setIsMenuCollapsed}
          searchData={searchData}
        />
        <Navbar
          isMenuCollapsed={isMenuCollapsed}
          setIsMenuCollapsed={setIsMenuCollapsed}
          searchData={searchData}
        />
        <Body>
          {children}
        </Body>
      </Main>
    </>
  )
}

export default Layout