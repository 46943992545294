export const color = {
  inputBackground: '#F2F4F5',
  divider: '#E9EDEF',
  primary: '#323437',
  secondary: '#626568',
  tertiary: '#C5C8CB',
  brandWash: '#FFF8E8',
  brandLight: '#FFEDC7',
  brandPrimary: '#FFD273',
  brandDark: '#F0AD22',
  washOffWhite: '#F8FAFC',
  washWhite: '#FFFFFF',
  positive: '#00B780',
  negative: '#C3334D',
}

export const fontStack = `'Inter', Helvetica, Arial, sans-serif`

export const font = {
  stack: {fontStack},
  h1: `
    font-family: ${fontStack};
    font-size: 3.375rem;
    line-height: 3.375rem;
    letter-spacing: -0.01875rem;
    font-weight: 800;
  `,
  h2: `
    font-family: ${fontStack};
    font-size: 1.5rem;
    line-height: 2rem;
    letter-spacing: -0.009375rem;
    font-weight: 800;
  `,
  h3: `
    font-family: ${fontStack};
    font-size: 1rem;
    line-height: 1.75rem;
    font-weight: 700;
  `,
  h4: `
    font-family: ${fontStack};
    font-size: 0.75rem;
    line-height: 1.25rem;
    font-weight: 700;
  `,
  p: `
    font-family: ${fontStack};
    font-size: 1rem;
    line-height: 1.75rem;
    font-weight: 400;
  `,
  pLink: `
    font-family: ${fontStack};
    font-size: 1rem;
    line-height: 1.75rem;
    font-weight: 700;
  `,
  meta: `
    font-family: ${fontStack};
    font-size: 0.75rem;
    line-height: 1.25rem;
    font-weight: 400;
  `,
  metaEmphasized: `
    font-family: ${fontStack};
    font-size: 0.75rem;
    line-height: 1.25rem;
    font-weight: 600;
  `,
  label: `
    font-family: ${fontStack};
    font-size: 0.875rem;
    line-height: 1.25rem;
    font-weight: 400;
  `,
  button: `
    font-family: ${fontStack};
    font-size: 1rem;
    line-height: 1rem;
    font-weight: 700;
  `,
}

export const elevation = {
  header: '0 0.0625rem 0.0625rem rgba(0, 0, 0, 0.04), 0 0 1rem -0.5rem rgba(0, 0, 0, 0.1)',
  card: '0 0.0625rem 0.0625rem rgba(0, 0, 0, 0.04), 0 0.25rem 0.5rem -0.25rem rgba(0, 0, 0, 0.08), 0 0.5rem 1rem -1.25rem rgba(0, 0, 0, 0.12)',
  cardHover: '0 0.0625rem 0.0625rem rgb(0 0 0 / 6%), 0 0.5rem 1rem -0.25rem rgb(0 0 0 / 12%), 0 0.75rem 1.5rem -1.25rem rgb(0 0 0 / 16%)',
  button: '0 1px 1px 0 rgba(0,0,0,0.04), 0 2px 8px -2px rgba(0,0,0,0.12)',
  buttonHover: '0 1px 1px 0 rgba(0,0,0,0.04), 0 4px 16px -4px rgba(0,0,0,0.16)',
}

export const transition = {
  easeOut: 'cubic-bezier(0.22, 1, 0.36, 1)',
  easeInOut: 'cubic-bezier(.44,.22,.57,.9)',
}