import * as React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import { color, font, elevation } from '../styles'
import logo from './logo.svg'
import Search from './search'
import iconMenu from './icon-menu.svg'
import iconClose from './icon-close.svg'

const Wrapper = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 3;
  background: ${color.washWhite};
  height: 4rem;
  width: 100%;
  padding: 1rem 0.75rem 1rem 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  box-shadow: ${elevation.header};
`
const HeaderLogo = styled(Link)`
  display: flex;
  align-items: center;
`
const Title = styled.h2`
  ${font.h3}
  color: ${color.primary};
`
const Logo = styled.img`
  width: 2rem;
  height: 2rem;
  margin-right: 1rem;
`
const Right = styled.div`
  display: flex;
`
const EmojiButton = styled.a`
  font-size: 1.5rem;
  line-height: 1.5rem;
  padding: 0.5rem;
  border-radius: 0.5rem;
  cursor: pointer;
  &:hover {
    background: ${color.inputBackground};
  }
  &:nth-child(2) {
    margin-left: 0.75rem; 
  }
  &:nth-child(3) {
    margin-left: 0.25rem;  
  }
  @media (min-width: 1280px) {
    display: block;
  }
  @media (max-width: 1279px) {
    display: none;
  }
`
const MobileMenu = styled.div`
  padding: 0.5rem;
  background: ${color.washWhite};
  border-radius: 0.5rem;
  cursor: pointer;
  &:hover {
    background: ${color.washOffWhite};
  }
  @media (min-width: 1280px) {
    display: none;
  }
  @media (max-width: 1279px) {
    display: block;
  }
`

const Header = ({ isMenuCollapsed, setIsMenuCollapsed, searchData }) => {
  return (
    <Wrapper>
      <HeaderLogo to={`/`} >
        <Logo src={logo} alt="Design Foundations" />
        <Title>Design Foundations</Title>
      </HeaderLogo>
      <Right>
        <Search
          index={searchData.localSearchArticles.index}
          store={searchData.localSearchArticles.store}  
        />
        <EmojiButton href="https://games.designfoundations.com/" target="_blank">
          <span role="img" aria-label="author">🎲</span>
        </EmojiButton>
        <EmojiButton href="https://michaelcroft.com.au" target="_blank">
          <span role="img" aria-label="author">🙋‍♂️</span>
        </EmojiButton>
        <MobileMenu onClick={() => setIsMenuCollapsed(!isMenuCollapsed)}>
          <img src={isMenuCollapsed ? iconMenu : iconClose} alt='menu'/>
        </MobileMenu>
      </Right>
    </Wrapper>
  )
}

export default Header
