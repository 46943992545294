import React, { useState } from 'react'
import styled from 'styled-components'
import { color, font } from '../styles'
import NavItem from './navItem'
import iconOpen from './icon-category-open.svg'
import iconClosed from './icon-category-closed.svg'
import iconChevron from './icon-chevron-right.svg'

const CategoryContainer = styled.div`
  border-bottom: 1px solid ${color.divider};
  cursor: pointer;
`

const CategoryTitle = styled.div`
  background: ${color.washWhite};
  padding: 1rem;
  height: 3.5rem;
  display: flex;
  align-items: center;
  z-index: 1;
  ${props => props.isExpanded && `border-bottom: 1px solid ${color.divider};`}
  img:first-child {
    margin-right: 0.75rem;
  }
  p {
    ${font.label}
    color: ${color.primary};
    flex-grow: 1;
  }
  img:last-child {
    margin-left: 0.75rem;
    transition: all 0.12s ease-in-out;
    transform: ${props => props.isExpanded && "rotate(90deg)"}
  }
`
const ArticleList = styled.div`
  height: ${props => !props.isExpanded && '0px'};
  overflow: hidden;
`

const NavCategory = ({ articles, setIsMenuCollapsed }) => {
  const [isExpanded, setIsExpanded] = useState(true)
  return (
    <CategoryContainer >
      <CategoryTitle
        isExpanded={isExpanded}
        onClick={() => setIsExpanded(!isExpanded)}
      >
        <img src={isExpanded ? iconOpen : iconClosed} alt="category" />
        <p>{articles[0].frontmatter.module}</p>
        <img src={iconChevron} alt={isExpanded ? "close" : "open"}/>
      </CategoryTitle>
      <ArticleList isExpanded={isExpanded}>
        {articles.map((article, i) => <NavItem key={i} article={article} setIsMenuCollapsed={setIsMenuCollapsed} />)}
      </ArticleList>
    </CategoryContainer>
  )
}

export default NavCategory